.footer {
    background-color: var(--secondary-color);
    font-family: "montserrat light";
    color: #fff
}

.footer__text {
    .copy {
        font-size: 13px;
        color: #aaa;
        font-family: 'Circular Std';
        font-weight: 500;
        letter-spacing: 0.27px;
        opacity: .8;
        align-self: center;
        justify-content: center;
        text-align: center;
    }
    .copy p{
        margin: 0;
        padding: 0;
    }
    .copy a {
        color: #aaa;
        text-decoration: none;
        font-weight: 600;
    }
    .copy .mediahaus-link{
        color: #00a1ff !important;
    }
    .copy .mediahaus-link:hover {
        text-decoration: none !important;
    }
    .copy .reservated{
        font-size: 10px;
    }
}

@media (max-width: 992px) {
    .footer__text {
        font-size: .8rem;
        .copy p{
            text-align: center;
        }
    }
}