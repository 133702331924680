.novedad {
    .main {
        .container-fluid-85 {
            .div_breadcrums {
                margin-top: 8rem;
            }
            .primer_bloque {
                .div_novedad {
                    .publicado_por {
                        letter-spacing: 0px;
                        text-transform: none;
                    }
                    .titulo {
                        letter-spacing: 0px;
                    }
                    .subtitulo {
                        color: $color-0;
                    }
                    .fecha {}
                    .texto_novedad {
                        margin-top: 1.5rem;
                        color: $color-1;
                        .rdw-editor-wrapper {
                            .rdw-editor-toolbar {
                                display: none;
                            }
                            .rdw-editor-main {
                                color: $color-1 !important;
                                .DraftEditor-root {
                                    .DraftEditor-editorContainer {
                                        .public-DraftEditor-content {
                                            span {
                                                color: $color-1 !important;
                                                font-family: "sourceSansPro-light" !important;
                                                background: transparent !important;
                                                font-size: var(--font-size-20) !important;
                                            }
                                            a {
                                                span {
                                                    color: $color-0 !important;
                                                }
                                            }
                                            div {
                                                h3 {
                                                    div {
                                                        span {
                                                            color: $color-1;
                                                        }
                                                    }
                                                }
                                                div {
                                                    div {
                                                        // span {
                                                        //   color: $color-1 !important;
                                                        //   font-family: "sourceSansPro-light" !important;
                                                        //   background: transparent !important;
                                                        //   font-size: var(--font-size-24) !important;
                                                        // }
                                                    }
                                                }
                                                figure {
                                                    iframe {
                                                        width: 100%;
                                                        height: 350px;
                                                    }
                                                    span.rdw-image-alignment.rdw-image-center {
                                                        span.rdw-image-imagewrapper {
                                                            img {
                                                                width: 100%;
                                                                object-fit: cover;
                                                                height: 50vh;
                                                                @media(max-width: 992px) {
                                                                    height: 40vh;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .public-DraftStyleDefault-block {
                                    margin: 0;
                                    line-height: 1.25;
                                }
                                .public-DraftStyleDefault-ul {
                                    margin: 0;
                                }
                                .public-DraftStyleDefault-ul+div>.public-DraftStyleDefault-block {
                                    margin: 0;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                    .hr_novedad {}
                    .pills {
                        .pill {}
                    }
                    .banner-container {
                        display: flex;
                        max-width: 100%;
                        flex-direction: column;
                        .banner-image {
                            height: 50vh;
                            display: flex;
                            object-fit: cover;
                            @media(max-width: 992px) {
                                height: 20vh;
                            }
                        }
                        .banner-caption {
                            opacity: .25;
                            color: $color-1;
                            padding: 0.5rem;
                            font-size: .9rem;
                            transition: all .3s;
                            align-self: flex-end;
                        }
                    }
                }
            }
            .segundo_bloque {
                border: 2px solid $bd-0;
                border-radius: 0px 0px 0px 100px;
                .circlePrimary {
                    position: absolute;
                    top: 24rem;
                    right: 0;
                    width: 85rem;
                }
                .titulo {
                    color: $color-0;
                    letter-spacing: 2px;
                }
                .div_novedades_destacadas {
                    .row {
                        padding-right: 5rem;
                    }
                }
            }
        }
    }
    .buttonAnimation {
        padding: .9rem 2rem;
        margin-bottom: 3rem;
        display: inline-block;
        height: unset;
        color: #fff !important;
    }
}

@media (max-width: 1700px) {
    .novedad {
        .main {
            .container-fluid-85 {
                .div_breadcrums {}
                .primer_bloque {
                    .div_novedad {
                        .publicado_por {}
                        .titulo {}
                        .subtitulo {}
                        .fecha {}
                        .texto_novedad {
                            .rdw-editor-wrapper {
                                .rdw-editor-toolbar {}
                                .rdw-editor-main {
                                    .DraftEditor-root {
                                        .DraftEditor-editorContainer {
                                            .public-DraftEditor-content {
                                                div {
                                                    div {
                                                        div {
                                                            span {}
                                                        }
                                                    }
                                                    figure {
                                                        iframe {}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .hr_novedad {}
                        .pills {
                            .pill {}
                        }
                    }
                }
                .segundo_bloque {
                    .titulo {}
                    .div_novedades_destacadas {
                        .row {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .novedad {
        .main {
            .container-fluid-85 {
                .div_breadcrums {}
                .primer_bloque {
                    .div_novedad {
                        .publicado_por {}
                        .titulo {}
                        .subtitulo {}
                        .fecha {}
                        .texto_novedad {
                            .rdw-editor-wrapper {
                                .rdw-editor-toolbar {}
                                .rdw-editor-main {
                                    .DraftEditor-root {
                                        .DraftEditor-editorContainer {
                                            .public-DraftEditor-content {
                                                div {
                                                    div {
                                                        div {
                                                            span {}
                                                        }
                                                    }
                                                    figure {
                                                        iframe {
                                                            width: 100%;
                                                            height: 250px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .hr_novedad {}
                        .pills {
                            .pill {}
                        }
                    }
                }
                .segundo_bloque {
                    .titulo {}
                    .circlePrimary {
                        position: absolute;
                        top: 5rem;
                        right: 0;
                        width: 50rem;
                    }
                    .div_novedades_destacadas {
                        .row {}
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .novedad {
        .main {
            .container-fluid-85 {
                .div_breadcrums {}
                .primer_bloque {
                    .div_novedad {
                        .publicado_por {}
                        .titulo {}
                        .subtitulo {}
                        .fecha {}
                        .texto_novedad {
                            .rdw-editor-wrapper {
                                .rdw-editor-toolbar {}
                                .rdw-editor-main {
                                    .DraftEditor-root {
                                        .DraftEditor-editorContainer {
                                            .public-DraftEditor-content {
                                                div {
                                                    div {
                                                        div {
                                                            span {}
                                                        }
                                                    }
                                                    figure {
                                                        iframe {}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .hr_novedad {}
                        .pills {
                            .pill {}
                        }
                    }
                }
                .segundo_bloque {
                    .titulo {}
                    .div_novedades_destacadas {
                        .row {
                            .div_card_new {
                                .card_new {
                                    width: 75%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .novedad {
        .main {
            .container-fluid-85 {
                padding: 2rem;
                .div_breadcrums {
                    margin-top: 3rem;
                }
                .primer_bloque {
                    .div_novedad {
                        .publicado_por {
                            font-size: .8rem !important;
                        }
                        .titulo {
                            font-size: 1.5rem !important;
                        }
                        .subtitulo {
                            font-size: 1rem !important;
                        }
                        .fecha {}
                        .texto_novedad {
                            .rdw-editor-wrapper {
                                .rdw-editor-toolbar {}
                                .rdw-editor-main {
                                    .DraftEditor-root {
                                        .DraftEditor-editorContainer {
                                            .public-DraftEditor-content {
                                                div {
                                                    div {
                                                        div {
                                                            span {}
                                                        }
                                                    }
                                                    figure {
                                                        iframe {}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .hr_novedad {}
                        .pills {
                            .pill {}
                        }
                    }
                }
                .segundo_bloque {
                    border: none;
                    .titulo {}
                    .div_novedades_destacadas {
                        .row {
                            .div_card_new {
                                .card_new {
                                    width: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}