.Emprendimiento {
    .breadcrumb-item {
        margin-bottom: 3rem;
    }
    .data {
        .container-fluid {
            padding: 8vh 0 6vh 0;
            overflow: hidden;
            position: relative;
            .index-1 {
                z-index: -1;
                .line-vertical {
                    border-color: #0F0F0F;
                    opacity: .15;
                    z-index: 4;
                }
            }
            .col-lg-4.col-xl-3 {
                width: 100%;
                .both {
                    // width: 33%;
                    width: 10%;
                    margin-right: 6.6%;
                    &.large {
                        width: 30%;
                    }
                }
            }
            .type {
                font-size: 1rem;
                padding: .4rem 1rem;
                color: #fff;
                font-weight: 300;
                margin-bottom: 2rem;
                display: inline-block;
            }
            .address {
                font-size: 5rem;
                color: var(--secondary-color);
                margin: 0rem 0 2rem 0;
                font-weight: 200;
            }
            p {
                font-size: 1.5rem;
                font-weight: 200;
                line-height: 1.25;
                width: 90%;
                color: #0F0F0F;
            }
            .line {
                margin-top: 6vh;
                display: block;
                width: 100%;
                height: 1rem;
            }
            .btn-square {
                width: 3.5rem;
                height: 3.5rem;
                font-size: 1rem;
                line-height: 1;
                font-weight: 300;
                border: 2px solid;
                z-index: 99;
                &:hover {
                    background: var(--secondary-color) !important;
                    border-color: var(--secondary-color);
                    i {
                        color: #fff;
                    }
                }
            }
            .item {
                font-weight: 400;
                .tick {
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 100%;
                    i {
                        color: #fff;
                        font-size: .5rem;
                    }
                }
            }
            .both {
                margin-top: 2.3rem;
                h5 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 1.5rem;
                    font-weight: 200;
                }
            }
            .map {
                height: 60vh;
                object-fit: cover;
                object-position: center;
                margin-top: 6rem;
            }
        }
        &.dark {
            background-color: var(--secondary-color);
            .address,
            .type,
            p {
                color: #fff;
            }
            .item {
                color: #fff;
            }
            .both {
                h5,
                span {
                    color: #fff;
                }
            }
            .breadcrumb-item {
                color: #fff;
            }
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .data {
            padding: 0vh 0 15vh 0;
            .container-fluid {
                padding: 7vh 1rem 0 1rem;
                .type {
                    font-size: 1rem;
                }
                .address {
                    margin: 2rem 0;
                    font-size: 2.3rem;
                    .location {
                        font-size: 1.5rem;
                    }
                }
                .col-lg-4.col-xl-3 {
                    .both,
                    .both.large {
                        width: 44% !important;
                        margin-right: 0;
                        h5,
                        span {
                            font-size: 1.1rem;
                        }
                    }
                }
                .btn {
                    margin-bottom: 2rem;
                }
                p {
                    width: 100%;
                    font-size: 1.1rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}