.section__details__emprendimiento {
  padding-bottom: 2rem;
  background-color: var(--secondary-color);
  padding-top: 2rem;
}

.section__details__container {
  // height: 100vh;
  // padding: 2rem 0;
}

.section__image__container > img {
  width: 48rem;
  object-fit: cover;
  object-position: center;
}

.section__1 {
  margin-left: 0 !important;
}

.section__2 {
  margin-right: 0 !important;
  .section__image__container2 {
    margin-left: 20vw;
  }
}

.left-image-float {
  left: 0;
  right: unset;
}

.section__3 {
  margin-left: 0 !important;
}

.section__details__text {
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  left: -10%;
  padding: 4rem;
  background-color: #8398b9;
  width: 629px;
  height: 457px;
  opacity: 0.75;
  border-bottom-right-radius: 6rem;
  z-index: 2;
}

.section__details__text::before {
  content: "";
  position: absolute;
  top: -5%;
  right: -5%;
  width: 629px;
  height: 457px;
  border: 2px solid var(--white);
  border-bottom-right-radius: 6rem;
  z-index: 1;
}

.image__details__circles {
  display: none;
  width: 50vw;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  right: -50%;
  z-index: 99;
}

.section__details__text2 {
  position: absolute;
  top: 10%;
  right: 0%;
  padding: 4rem;
  background-color: #8e8e8e;
  width: 629px;
  height: 457px;
  opacity: 0.75;
  border-bottom-left-radius: 6rem;
  z-index: 2;
}

.section__details__text2::before {
  content: "";
  position: absolute;
  top: -5%;
  left: -5%;
  width: 629px;
  height: 457px;
  border: 2px solid var(--white);
  border-bottom-left-radius: 6rem;
  z-index: 1;
}

.image__details__bg {
  display: none;
  width: 40vw;
  top: 80%;
  transform: translate(-50%, -50%);
  right: -35%;
  z-index: 99;
}

.section__details__text3 {
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  left: 5%;
  padding: 4rem;
  background-color: #8398b9;
  width: 629px;
  height: 457px;
  opacity: 0.75;
  border-bottom-right-radius: 6rem;
  z-index: 2;
}

.section__image__3 {
  width: 803px !important;
  height: 804px !important;
}

@media (max-width: 1600px) {
  .section__details__text3 {
    left: 30%;
  }
}

@media (max-width: 1400px) {
  .section__details__text {
    left: 10%;
  }
}

@media (max-width: 2400px) {
  .section__details__text {
    left: 15%;
  }
  .section__details__text3 {
    left: 40%;
  }
}

@media (max-width: 992px) {
  .section__image__container > img {
    width: 90% !important;
  }
  .section__image__container {
    .section__image__3 {
      height: 100vw !important;
      width: 100vw !important;
    }
  }
  .section__details__emprendimiento {
    padding-bottom: 0;
    img {
      height: 50vh !important;
      width: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }
  .section__details__text2,
  .section__details__text,
  .section__details__text3 {
    right: -0%;
    width: unset;
    height: unset;
    padding: 3rem 1.5rem;
    font-size: 0.9rem;
    p {
      font-size: 0.9rem;
    }
  }
  .section__details__text2 {
    height: 35vh;
    &::before {
      height: 35vh;
    }
  }
  .section__details__text3 {
    left: 50% !important;
  }
  .section__details__text,
  .section__details__text3 {
    left: 30%;
    width: 80vw;
    object-position: center;
    object-fit: cover;
    height: 30vh;
    &::before {
      width: 80vw;
      height: 30vh;
    }
  }
  .section__2 {
    margin-right: auto !important;
    .section__image__container2 {
      margin-left: 0vw;
    }
  }
}
