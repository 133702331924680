.popup-overlay {
    width: 100vw;
    overflow: hidden;
    z-index: 10000000!important;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
    border: 0;
    outline: 0;
    overflow: hidden;
    background: #e6e6e6!important;
    background-color: #e6e6e6!important;
    border: 1px solid #e6e6e6!important;
    padding: 4rem 6rem 5rem 6rem!important;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    &:focus,
    &:active {
        border: 0;
        outline: 0;
    }
    .close {
        top: 1.5rem;
        right: 1.5em;
        border: none;
        z-index: 999;
        color: #1f1f1f;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        font-size: 1.5rem;
        position: absolute;
        // transform: rotate(45deg);
        background-color: transparent;
        &:active,
        &:focus {
            border: 0;
            outline: 0;
        }
    }
    .content-border {
        margin: auto;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;
        padding: 4.5rem 4rem 4rem 4rem!important;
        &.show-band {
            border: none;
        }
        &.hide-band {
            border: 1px solid #fff;
        }
        svg.square-content {
            left: 0;
            top: 50%;
            right: 0;
            bottom: 0;
            width: 100%;
            position: absolute;
            transform: translate(0%, -50%);
            &.show {
                display: block;
            }
            &.hide {
                display: none;
            }
            @media (max-width: 1660px) {
                display: none!important;
            }
        }
        .band {
            top: 0;
            width: 100%;
            right: -50%;
            color: #fff;
            display: flex;
            height: 2.5rem;
            position: absolute;
            align-items: center;
            justify-content: center;
            transform: rotate(43deg);
            background: $secondary-color;
            @media (max-width: 500px) {
                right: -40%;
            }
            .text-band {
                width: 23%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: uppercase;
                @media (max-width: 500px) {
                    width: 25%;
                    margin-right: 2rem;
                }
            }
        }
        .star-icon {
            top: -1.4rem;
            left: -1.4rem;
            width: 2.8rem;
            height: 2.8rem;
            position: absolute;
            background-color: #e6e6e6;
            border-radius: 50%;
            // border: 1px solid $secondary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                font-size: 2.25rem;
                color: $secondary-color;
            }
            // i.star-icon-svg {
            //     width: auto;
            // }
            @media (max-width: 500px) {
                left: -0.9rem;
            }
        }
        h1 {
            width: 94%;
            font-weight: 400;
            color: $primary-color;
            font-size: calc(1.375rem + 1.5vw)!important;
            @media (min-width: 1200px) {
                font-size: 2.5vw !important;
            }
            @media (max-width: 576px) {
                font-size: calc(1.1rem + 1vw) !important
            }
        }
        p.popup-text {
            color: #1f1f1f;
            max-width: 75%;
            font-weight: 200;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .counter {
            .date-val {
                color: $primary-color;
                padding: .5rem;
                min-width: 4.5rem;
                margin: 0 0.5rem;
                font-size: 2.5rem;
                text-align: center;
                border: 1px solid grey;
                @media (min-width: 361px) and (max-width: 576px) {
                    min-width: 3.5rem;
                    font-size: 1.5rem;
                }
                @media (max-width: 360px) {
                    min-width: 3rem;
                    font-size: 1.25rem;
                }
            }
            .date-text {
                color: grey;
                font-size: 1.2rem;
                margin-top: .5rem;
                text-align: center;
            }
        }
        a {
            left: auto;
            right: auto;
            color: #fff;
            display: block;
            bottom: -3.1rem;
            font-weight: 400;
            font-size: 1.1rem;
            text-align: center;
            position: absolute;
            padding: 1.4rem 3.5rem;
            background: $primary-color;
            transition: .2s ease-in-out all;
            &:hover {
                color: #fff;
                background-color: $secondary-color;
            }
            &:active,
            &:focus {
                border: 0;
                outline: 0;
            }
        }
    }
}

@media (max-width: 1400px) {
    .popup-content {
        width: 60vw;
        .content-border {
            a {
                left: auto;
                right: auto;
            }
        }
    }
}

@media (max-width:992px) {
    .popup-overlay {
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.7);
    }
    #popup-root {
        .popup-content {
            width: 90vw;
            background-color: #0f0f0fe7;
            .close {
                top: 1.5rem;
                right: 1.5em;
            }
            .content-border {
                padding: 4.5rem 2rem 4rem 2rem!important;
                a {
                    bottom: -1rem;
                    font-size: 1rem;
                    padding: 1rem 2rem;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    #popup-root {
        .popup-content {
            padding: 4rem 1rem 5rem 1rem !important;
            .close {
                top: 1rem;
                right: 1rem;
            }
        }
    }
}


/* show animation */

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}