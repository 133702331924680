.main__first__section__contact {
  width: 100vw;
}

.main__first__section__container__contact {
  position: relative;
  width: 100%;
  z-index: 1;
  .owl-carousel {
    height: 100vh;
    .owl-item {
        width: 100%;
        margin: 0;
        .art {
            .text-content {
                z-index: 2;
                text-align: right;
                height: 100vh;
                padding-top: 10vh;
                @media (max-width:992px) {
                    text-align: center;
                }
                h2 {
                    font-size: 3.5rem;
                    font-family: 'montserrat bold', sans-serif;
                    letter-spacing: 1px;
                    @media (max-width:992px) {
                        font-size: 2rem;
                        align-self: center;
                        text-align: center !important;
                    }
                }
                h4 {
                    margin: 1.4rem 0 1.4rem 0;
                    font-size: 1.8rem;
                    max-width: 55vw;
                    letter-spacing: 1px;
                    @media (max-width:992px) {
                        width: 100%;
                        font-size: 1rem;
                        margin: 1rem auto;
                        text-align: center;
                    }
                }
                p {
                    font-weight: 300;
                    font-size: 1.3rem;
                    letter-spacing: 1.5px;
                    line-height: 1.4;
                    max-width: 55vw;
                    margin-bottom: 4rem;
                    font-family: 'montserrat regular', sans-serif;
                    @media (max-width:992px) {
                        width: 100%;
                        font-size: .8rem;
                        max-width: 100vw;
                        margin: 1rem auto;
                        text-align: center;
                        margin-bottom: 2rem;
                    }
                }
                a {
                    background-color: transparent;
                    transition: .3s ease-in-out all;
                    border: 2px solid var(--btn-primary-border);
                    color: var(--btn-primary-color);
                    padding: 1.2rem 4rem;
                    letter-spacing: 1px;
                    font-size: 1.2rem;
                    font-family: 'montserrat bold', sans-serif;
                    text-transform: uppercase;
                    @media (max-width:992px) {
                        padding: 1rem 3rem;
                        text-align: center;
                        font-size: .8rem;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    &:hover {
                        background-color: var(--btn-primary-border);
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
            img,
            .opacity {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                height: 100vh;
                object-fit: cover;
            }
            .opacity {
                background-color: #333;
                opacity: .5;
                z-index: 1;
            }
        }
    }
  }
  .arrow__container {
    @media (min-width:992px) {
      transform: translate(-100%, -50%)
    }
  }
}

.main__first__section__background__contact {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100vh;
}

.main__first__section__title__contact {
  position: absolute;
  top: 0%;
  color: var(--white);
  height: 100vh;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4939017843465511) 26%,
    rgba(0, 0, 0, 0) 48%,
    rgba(21, 26, 47, 0.94) 90%
  );
}

.main__title__contacto__contact {
  position: absolute;
  bottom: 15rem;
  letter-spacing: 0.2rem;
}

.bgCircle_contacto {
  width: 63vw;
  position: absolute;
  right: -50vh;
  top: 50vh;
  display: none;
}

@media screen and (min-width: 720px) {
  .main__title__contacto__contact {
    position: absolute;
    // text-align: right;
    bottom: 15rem;
  }
}

@media (max-width: 992px) {
  .main__second__section__contacto {
    padding: 3rem 0 !important;
    p {
      line-height: 1.4;
    }
  }
}
