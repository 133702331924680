.header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0;
    z-index: 9999;
}

.header__top {
    background-color: #282828;
    height: 50px;
    z-index: 9999;
}

.header__emprendimiento__title {
    color: #848484;
    display: block;
    width: inherit;
    text-align: center;
}

.button__container {
    position: absolute;
    right: 0;
    a {
        font-family: "sourceSansPro-regular" !important;
        text-decoration: none !important;
    }
}

.button__emprendimiento {
    padding: .7rem 1rem;
    height: 34px;
    background-color: var(--primary-color);
    border: unset;
    outline: unset;
    text-decoration: none;
}

.main__second__header__emprendimiento {
    background-color: #282828 !important;
    opacity: .84;
    height: 64px;
    position: relative;
    z-index: 99;
    img {
        height: 60px
    }
    li {
        a {
            color: #fff;
            letter-spacing: 3px;
            text-decoration: none;
            font-size: .9rem;
            &:hover {
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

@media (max-width:992px) {
    .header__top {
        padding: .2rem 0;
        height: unset;
        .header__emprendimiento__title {
            font-size: .8rem;
            width: auto;
        }
    }
    .main__second__header__emprendimiento {
        nav {
            display: none !important;
        }
    }
    .button__container {
        position: relative;
        margin: .5rem 0;
        margin-left: auto;
        text-align: center;
        display: inline-block;
        width: auto;
        font-size: .8rem;
    }
}