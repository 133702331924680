.form-control {
	height: 44px !important;
	margin-top: 1.5rem;
	position: relative;
	padding: .375rem 0 !important;
	border: unset !important;
	padding-bottom: 15px !important;
	border-bottom: 1px solid #B9B9B9 !important;
	background-color: unset !important;
	color: #B9B9B9 !important;
	border-radius: 0 !important;
	font-size: 18px !important;

	&:hover {
		box-shadow: none !important;
		border: unset;
	}
  
	&:focus {
		box-shadow: none !important;
		border: unset;
	}
}

textarea {
	resize: none !important;
	overflow: hidden !important;
	margin-bottom: 4.5rem;
	padding: .375rem 0 !important;
	font-size: 18 !important;
}

.both-form {
	justify-content: center !important;
}

.btn-form {
	background-color: #B18442 !important;
	width: 14rem;
	height: 4rem;
	border-bottom-right-radius: 50px !important;
	transition: all .3s !important;

	&:hover {
		border-bottom-right-radius: 4px !important;
	}
}
