.novedad {
    .main__first__section__novedad {
        .main__first__section__container__novedad {
            position: relative;
            width: 100%;
            height: 392px;
            .main__first__section__background__novedad {
                width: 100%;
                height: 392px;
                object-fit: cover;
                object-position: center;
            }
            .main__first__section__title__novedad {
                position: absolute;
                top: 0%;
                color: var(--white);
                height: 392px;
                width: 100%;
                left: 10rem;
                .main__first__section__title__container__novedad {
                    h1 {
                        color: black;
                    }
                    .div_bottom_arrow {
                        //   transform: rotate(90deg);
                        margin-top: 3rem;
                        z-index: 1;
                        position: relative;
                        a {
                            border: 1px solid white;
                            border-radius: 49%;
                            padding: 2.2rem;
                            position: absolute;
                            transition: 1s all;
                            span {
                                position: absolute;
                                font-size: 1rem;
                                color: white;
                                top: 25px;
                                left: 26px;
                                transform: rotate(90deg);
                                transition: 1s all;
                            }
                            &:hover {
                                border-color: orange;
                                span {
                                    top: 35px;
                                    color: black;
                                }
                            }
                        }
                    }
                }
            }
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: transparent linear-gradient( 180deg, #202020a7 0%, var(--unnamed-color-151a2f) 100%) 0% 0% no-repeat padding-box;
                background: transparent linear-gradient(180deg, #202020a7 0%, #151a2f 100%) 0% 0% no-repeat padding-box;
                opacity: 0.8;
            }
        }
    }
}

@media (max-width: 992px) {
    .novedad {
        .main__first__section__novedad {
            .main__first__section__container__novedad {
                .main__first__section__background__novedad {}
                .main__first__section__title__novedad {
                    left: 0;
                    .main__first__section__title__container__novedad {
                        h1 {
                            text-align: center;
                        }
                        .div_bottom_arrow {
                            a {
                                span {
                                    left: 20px;
                                }
                                &:hover {
                                    span {}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .novedad {
        .main__first__section__novedad {
            .main__first__section__container__novedad {
                height: 186px;
                .main__first__section__background__novedad {
                    height: 186px;
                }
                .main__first__section__title__novedad {
                    height: 186px;
                    left: 0;
                    .main__first__section__title__container__novedad {
                        margin-top: 10vh !important;
                        p {
                            font-size: 1.2rem;
                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}