.buttonGhost,
.buttonGhostTwo {
  padding: 0 4rem;
  height: 5rem;
  border: 1px solid $bd-0 !important;
  color: $color-1;
  background-color: transparent;
  font-weight: bold;
  transition: all 0.3s;
  font-size: 20px;
  border-radius: 0 0 35px 0;
  &:hover {
    background-color: transparent;
    color: $color-0;
    border-radius: 0 !important;
    text-decoration: none;
  }
}

@media (max-width: 992px) {
  .buttonGhost,
  .buttonGhostTwo {
    padding: 0 1.5rem;
    font-size: 0.9rem;
  }
}
