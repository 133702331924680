.Inicio {
    position: relative;
    .main {
        height: calc(100vh);
        position: relative;
        z-index: 1;
        background-color: var(--secondary-color);
        .owl-carousel {
            height: 100vh;
            .owl-item {
                width: 100%;
                margin: 0;
                .art {
                    .text-content {
                        z-index: 2;
                        text-align: right;
                        height: 100vh;
                        padding-top: 10vh;
                        h2 {
                            font-size: 3.5rem;
                            font-family: 'montserrat bold', sans-serif;
                            letter-spacing: 1px;
                        }
                        h4 {
                            margin: 1.4rem 0 1.4rem 0;
                            font-size: 1.8rem;
                            max-width: 55vw;
                            letter-spacing: 1px;
                        }
                        p {
                            font-weight: 300;
                            font-size: 1.3rem;
                            letter-spacing: 1.5px;
                            line-height: 1.4;
                            max-width: 55vw;
                            margin-bottom: 4rem;
                            font-family: 'montserrat regular', sans-serif
                        }
                        a {
                            background-color: transparent;
                            transition: .3s ease-in-out all;
                            border: 2px solid var(--btn-primary-border);
                            color: var(--btn-primary-color);
                            padding: 1.2rem 4rem;
                            letter-spacing: 1px;
                            font-size: 1.2rem;
                            font-family: 'montserrat bold', sans-serif;
                            text-transform: uppercase;
                            &:hover {
                                background-color: var(--btn-primary-border);
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }
                    img,
                    .opacity {
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        height: 100vh;
                        object-fit: cover;
                    }
                    .opacity {
                        background-color: #333;
                        opacity: .5;
                        z-index: 1;
                    }
                }
            }
        }
        .line-degrade {
            width: 40vw;
            position: absolute;
            bottom: 20vh;
            height: 1.1rem;
            z-index: 3;
            display: block;
        }
        #content-main {
            height: 80vh;
            position: relative;
            z-index: 3;
            padding-top: 8.3rem;
            h1 {
                font-size: 5vw;
                color: #fff;
                line-height: .9;
                font-weight: 200;
            }
            p {
                font-size: 1.6rem;
                color: #fff;
                font-weight: 200;
                margin-top: 2rem;
            }
        }
    }
}

@media (max-width:992px) {
    .Inicio {
        &::before {
            display: none;
        }
        .main {
            min-height: 100vh;
            max-height: 100vh;
            width: 100% !important;
            .owl-carousel {
                .owl-item {
                    .art {
                        .text-content {
                            text-align: center;
                            padding-top: 10vh;
                            h2 {
                                font-size: 2rem;
                                text-align: center !important;
                                width: 100%;
                            }
                            h4 {
                                margin: 1rem 0 1rem 0;
                                font-size: 1rem;
                                text-align: center;
                                // width: 90vw;
                                // max-width: 90vw;
                                width: 100%;
                                margin: 1rem auto;
                            }
                            p {
                                font-size: .8rem;
                                max-width: 100vw;
                                text-align: center;
                                // width: 90vw;
                                width: 100%;
                                margin: 1rem auto;
                                margin-bottom: 2rem;
                            }
                            a {
                                padding: 1rem 3rem;
                                text-align: center;
                                font-size: .8rem;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                        img,
                        .opacity {
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            height: 100vh;
                            object-fit: cover;
                        }
                        .opacity {
                            background-color: #333;
                            opacity: .5;
                            z-index: 1;
                        }
                    }
                }
            }
            // padding-top: 10vh;
            // .transparencia {
            //     background-color: #0F0F0F;
            //     opacity: .75;
            //     position: absolute;
            //     left: 0;
            //     right: 0;
            //     top: 0;
            //     bottom: 0;
            //     z-index: 21;
            // }
            // .line-degrade {
            //     display: none;
            // }
            // &::before {
            //     z-index: 9999;
            //     display: none;
            // }
            // &::after {
            //     left: 0vw;
            //     right: 0;
            //     height: 90vh;
            //     z-index: -1;
            // }
            // #content-main {
            //     position: relative;
            //     padding-top: 2rem;
            //     z-index: 999;
            //     h1 {
            //         font-size: 15vw;
            //     }
            //     p {
            //         margin-top: 1rem;
            //         margin-bottom: 0rem;
            //         font-size: 1.3rem;
            //     }
            //     form {
            //         .content-fields {
            //             background-color: rgba(rgb(0, 0, 0), .25);
            //             input,
            //             select {
            //                 font-size: .9rem;
            //                 padding-left: 2.5rem;
            //             }
            //             i {
            //                 font-size: .8rem;
            //             }
            //         }
            //         button.btn {
            //             width: 100%;
            //             margin-top: 2rem;
            //             font-size: 1rem;
            //             display: block;
            //             padding: 1rem;
            //         }
            //     }
            // }
            // .navigator-container {
            //     display: none !important;
            // }
            .arrow__container {
                img {
                    width: 1rem;
                }
            }
        }
    }
}