.section__five {
    background-color: var(--secondary-color);
    padding: 8rem 0;
    position: relative;
}

.section__five__container {
    padding: 8rem 0;
}

.section__five__title {
    font-size: 2.1rem !important;
    text-transform: uppercase;
    text-align: center;
}

.section__five a {
    &:hover {
        text-decoration: none;
        color: var(--btn-primary-border) !important;
    }
}

.section__five__arrow__top {
    position: absolute;
    right: 0;
    cursor: pointer;
    display: none;
    i {
        color: var(--btn-primary-color);
        font-size: 4.5rem;
    }
}

.texto-primary {
    color: #B18442 !important;
}

.contact {
    list-style: none;
    padding: 0;
}

.contact__item {
    font-size: 1.2rem;
    color: #B9B9B9;
    position: relative;
    z-index: 99;
    a {
        color: #B9B9B9;
        &:hover {
            color: #B18442;
            text-decoration: none;
        }
    }
}

.contact__email {
    font-size: 1.2rem;
}

.elipse_bg {
    display: none;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
}

@media screen and( min-width: 728px) {
    .contact__email {
        font-size: 1.5rem !important;
    }
    .contact__item {
        font-size: 1.5rem !important;
    }
}

@media (max-width:992px) {
    .section__five {
        padding: 3rem 0;
    }
    .section__five__container {
        padding: 1rem 0 3rem 0;
    }
    .contact {
        text-align: center;
    }
    #formContact {
        text-align: center;
        .form-control {
            text-align: center;
        }
        button {
            margin-left: auto;
            margin-right: auto;
        }
    }
}