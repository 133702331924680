.main__content {
  .div_equipo {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    .container-fluid {
      width: 75%;
      .div_equipo_container {
        position: relative;
        margin-top: 3rem;

        .div_equipo_content {
          padding-bottom: 7rem;
          .first_title {
            color: white;
          }
        }
        .div_card_personal {
          overflow: hidden;
          width: 230px;
          align-items: center;
          margin-right: 3rem;
          .div_img {
            border-radius: 50%;
            overflow: hidden;
            width: 230px;
            height: 230px;
            img {
              object-fit: cover;
              object-position: center;
              width: 100%;
            }
          }

          .info-personal {
            padding: 1rem;
            border: 1px solid var(--btn-primary-border);
            border-radius: 5px;
            z-index: 2;
            transition: 1s all;
            h4 {
              color: var(--color-0);
              text-transform: capitalize;
              letter-spacing: 0px;
            }
          }
          &:hover {
            img {
              transform: scale(1.2);
              transition: 1s all;
              bottom: 0;
              filter: sepia(100%);
            }
            .info-personal {
              margin-top: 0 !important;
              transition: 1s all;
              margin-bottom: 1.5rem !important ;
              background-color: var(--secondary-color);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .main__content {
    .div_equipo {
      .container-fluid {
        .div_equipo__title {
          h2 {
            &:first-child {
            }
          }
        }
        .container-fluid {
          .div_equipo_container {
            .div_equipo_content {
              .first_title {
              }
              .div_card_personal {
                width: 200px;
                .div_img {
                  width: 200px;
                  height: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .main__content {
    .div_equipo {
      padding: 0;
      .container-fluid {
        width: 95%;
        .div_equipo__title {
          h2 {
            &:first-child {
            }
          }
        }

        .div_equipo_container {
          .div_equipo_content {
            .first_title {
              font-size: 2rem !important;
            }
          }
          .div_card_personal {
            width: 250px;
            margin-right: 0;
            .div_img {
              width: 250px;
              height: 250px;
            }
            padding: 1rem;
          }
        }
      }
    }
  }
}
