.fixed-btn-wrapper {
    z-index: 1000;
    position: fixed;
    background: transparent;
    .btn-link {
        color: #fff;
        padding: 0.8rem 1rem 0.8rem 0.9rem;
        cursor: pointer;
        font-size: .7rem;
        word-spacing: 0.2rem;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 5px 0 0 5px;
        background-color: $primary-color;
        &:hover {
            background-color: #b18442ad;
        }
    }
    &.left {
        left: -30px;
        top: calc(100vh - 50%);
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        animation: toLeft 1s forwards;
    }
    &.right {
        right: -30px;
        top: calc(100vh - 50%);
        writing-mode: vertical-lr;
        transform: rotate(360deg);
        animation: toRight 1s forwards;
    }
}

@keyframes toLeft {
    from {
        left: -50px;
    }
    to {
        left: 7px;
    }
}

@keyframes toRight {
    from {
        right: -50px;
    }
    to {
        right: 7px;
    }
}