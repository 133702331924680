// .image__unities__bg {
//     position: absolute;
//     top: -50%;
//     left: 0;
// }

// .unities {
//     .proyect-bg.tag-filter {
//         padding: .4rem .8rem;
//         font-size: .9rem;
//         border-radius: 15px;
//         cursor: pointer;
//         border: 1px solid #fff;
//         background: transparent !important;
//         transition: .4s ease all;
//         &.active {
//             background: #fff !important;
//             border: 1px solid;
//             color: var(--secondary-color);
//         }
//     }
// }

// .unity {
//     margin-top: 10vh;
// }
.div_unidades {
  background: transparent;
  position: relative;
  margin-top: 1rem;
  .collapsed .sub-title {
    opacity: 0;
  }
  .fluid-1 {
    position: relative;
    h2 {
      color: $color-1;
    }
  }
}

.fluid-2 {
  h3 {
    color: $color-1;
    opacity: 0.5;
    margin-top: 3rem;
  }
  .unities {
    .div_ambientes {
      .div_tags {
        // justify-content: space-evenly;
        .tag-filter {
          // padding: 1rem 2rem;
          border-radius: 20px;
          cursor: pointer;
          border: 1px solid $color-1;
          background: transparent !important;
          transition: 0.4s ease all;
          color: $color-1;
          &.active {
            background: #fff !important;
            border: 1px solid;
            color: var(--secondary-color) !important;
          }
        }
      }
    }
    .div_pisos {
      .unity {
        // border-bottom: 1px solid $color-1;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &.collapsed {
          .col_arrow {
            i {
              transform: rotate(90deg) !important;
            }
          }
        }
        .head-row {
          .unity-font {
            color: $color-1;
          }
          .col_arrow {
            i {
              color: $color-1;
              transform: rotate(270deg);
            }
          }
        }
        .close-deployable {
          .unity-row {
            .unity-font {
              color: $color-1;
            }
          }
        }
      }
    }
    .div_unidad_inactiva {
      p {
        color: $color-1;
        margin-top: 7rem;
      }
    }
  }
}

@media (max-width: 1600px) {
  .div_unidades {
    .fluid-1 {
    }
  }
}

@media (max-width: 1600px) {
  .div_unidades {
    .fluid-1 {
      p {
      }
      h2 {
      }
    }
    .fluid-2 {
      h3 {
      }
      .unities {
        .div_ambientes {
          .div_tags {
            .tag-filter {
              &.active {
              }
            }
          }
        }
        .div_pisos {
          .unity {
            &.collapsed {
              .col_arrow {
                i {
                }
              }
            }
            .head-row {
              .unity-font {
                font-size: 1rem !important;
              }
              .col_arrow {
                i {
                }
              }
            }
            .close-deployable {
              .unity-row {
                .unity-font {
                  font-size: 1rem !important;
                }
              }
            }
          }
        }
        .div_unidad_inactiva {
          p {
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .div_unidades {
    .fluid-1 {
    }

    .fluid-2 {
      padding-left: 1rem;
      padding-right: 1rem;
      h3 {
      }
      .unities {
        .div_ambientes {
          .div_tags {
            justify-content: center;
            .tag-filter {
              font-size: 0.9rem;
              padding: 0.2rem !important;
              &.active {
              }
            }
          }
        }
        .div_pisos {
          .unity {
            &.collapsed {
              .col_arrow {
                i {
                }
              }
            }
            .head-row {
              .unity-font {
                font-size: 0.8rem !important;
              }
              .col_arrow {
                i {
                }
              }
            }
            .close-deployable {
              .unity-row {
                .unity-font {
                  font-size: 0.8rem !important;
                }
              }
            }
          }
        }
        .div_unidad_inactiva {
          p {
          }
        }
      }
    }
  }
}
