.propiedad {
  .data {
    background-color: var(--secondary-color) !important;
    padding-top: 10vh;
    overflow: hidden;
    max-width: 100vw;
    .breadcrumb-item + .breadcrumb-item {
      color: #fff;
      &::before {
        color: #fff;
      }
    }
    h2 {
      font-size: 3rem;
      margin-bottom: 0;
      line-height: 1;
      color: #fff;
      font-weight: 200;
      &.sub_title {
        font-weight: 200;
        color: #fff;
        margin-top: 10vh;
      }
    }
    .btn-square {
      padding: 0.4rem 1.5rem;
      border: 2px solid var(--primary-color);
      color: #fff;
      font-size: 1rem;
      margin-right: 0.7rem;
      transition: 0.3s ease all;
      &:hover {
        color: var(--primary-color);
        background: #fff;
        i {
          color: var(--primary-color);
        }
      }
      i {
        margin-right: 1rem;
      }
      &.small {
        width: 3rem;
        height: 3rem;
        padding: 0.4rem;
        color: #fff;
        .icon-solid-like {
          color: transparent;
          -webkit-text-stroke: 2px #fff;
        }
        i {
          margin-right: 0rem;
        }
        &:hover,
        &.active {
          i {
            color: #0f0f0f;
            -webkit-text-stroke: none !important;
          }
          .icon-solid-like {
            -webkit-text-stroke: 0px !important;
          }
        }
      }
    }
    .tag {
      padding: 0.5rem 1.3rem;
      font-size: 0.8rem;
      font-weight: 300;
      color: var(--secondary-color);
      background: #fff;
      &.primary-tag,
      &.Alquiler {
        color: #fff;
        border: 1px solid #fff;
      }
    }
    .item {
      color: #fff;
      .tick {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0 !important;
        i {
          color: #fff;
          font-size: 0.5rem;
        }
      }
    }
    .item-extra {
      margin-top: 0.5rem;
      font-size: 1.5rem;
      color: #c3c3c3;
      align-items: center;
      i {
        color: #797979;
        text-align: center;
        font-size: 2.5rem;
        &.icon-gym {
          font-size: 0.7rem;
        }
        &.icon-home {
          font-size: 1.1rem;
          margin-right: 1.8rem !important;
          display: block;
        }
      }
    }
    hr {
      height: 1rem;
      opacity: 1;
      margin: 7vh 0;
    }
    p {
      color: #ebebeb;
      line-height: 2.2rem;
      width: 100%;
      font-weight: 200;
    }
    .content-price {
      //   display: block;
      margin: 5rem 0 !important;
      margin-bottom: 2rem !important;
      display: flex;
      width: 100%;

      h3 {
        font-size: 3.75rem;
        font-weight: 600;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        min-width: fit-content;
        text-align: end;
        &::before {
          content: "";
          width: 2rem;
          height: 2rem;
          margin-right: 2.5rem;
          display: inline-block;
          position: absolute;
          left: 0;
        }
        &.Alquiler {
          &::before {
          }
        }
        &.Venta {
          &::before {
            // background: #fff;
          }
        }
        &.min-size {
          padding-left: 0;
          &::before {
            opacity: 0;
          }
        }
      }
    }
    .global_view {
      height: 60vh;
      width: 100%;
      margin-top: 3rem;
    }
    .project {
      width: 100% !important;
      height: 100% !important;
      max-width: 940px !important;
      max-height: 677px !important;
    }
  }
  .dblCircles__propiedad {
    position: absolute;
    top: 0;
    right: -35%;
  }
}

@media (max-width: 992px) {
  .propiedad {
    .dblCircles__propiedad {
      opacity: 0.3;
    }
    .data {
      padding: 5vh 0 0 0 !important;
      .price-container {
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
        .content-price {
          margin: 1rem 0 !important;
          h3 {
            margin: 3rem 0 1rem 0;
          }
        }
      }
      h2 {
        font-size: 2.6rem;
        &.plano-mobile {
          margin-bottom: 2rem !important;
        }
        &.sub_title {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        .location {
          font-size: 1.3rem;
          margin: 0.5rem 0 2rem 0;
        }
      }
      #ubicacion h2 {
        font-size: 2.5rem !important;
        margin-bottom: 1rem;
      }
      h5 {
        font-size: 1rem;
      }
      .btn {
        padding: 0rem 1rem;
        font-size: 0.6rem;
        margin-right: 0.5rem;
      }
      .tag {
        padding: 0.3rem 1rem;
        margin-bottom: 4vh;
        margin-top: 1rem;
        font-size: 0.7rem;
      }
      .item {
        margin-bottom: 1rem !important;
        .tick {
          width: 1rem;
          height: 1rem;
          border-radius: 0 !important;
        }
      }
      .item-extra {
        font-size: 0.8rem;
        // overflow-x: scroll;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        &:nth-child(4) {
          margin-bottom: 2vh;
        }
        i {
          width: 1.5rem;
          font-size: 1.5rem;
        }
      }
      p {
        font-size: 0.8rem;
        letter-spacing: 0;
        width: 100%;
      }
      .item {
        i {
          display: flex;
          width: 1rem;
          height: 1rem;
          justify-content: center !important;
          align-items: center !important;
        }
        p {
          text-align: left;
          line-height: 1.2;
        }
      }
      .content-price {
        margin: 5rem 0 !important;
        h3 {
          font-size: 2.5rem;
          min-width: unset;
          padding-left: 3rem;
        }
      }
      .contact-main {
        form {
          padding-top: 0vh !important;
        }
        h2 {
          font-size: 2rem !important;
        }
        .btn {
          font-size: 0.9rem;
          padding: 0 2rem;
          padding-left: calc(2rem + 3rem);
        }
      }
    }
  }
}
