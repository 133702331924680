@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?i53fod");
  src: url("../../fonts/icomoon.eot?i53fod#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?i53fod") format("truetype"),
    url("../../fonts/icomoon.woff?i53fod") format("woff"),
    url("../../fonts/icomoon.svg?i53fod#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email1:before {
  content: "\e98a";
}
.icon-premium:before {
  content: "\e98b";
}
.icon-finaciacion:before {
  content: "\e98c";
}
.icon-conectividad:before {
  content: "\e98d";
}
.icon-amenities1:before {
  content: "\e98e";
}
.icon-check1:before {
  content: "\e98f";
}
.icon-location-icon:before {
  content: "\e990";
}
.icon-quot:before {
  content: "\e991";
}
.icon-house:before {
  content: "\e992";
}
.icon-message:before {
  content: "\e993";
}
.icon-key-icon:before {
  content: "\e994";
}
.icon-arrow-up:before {
  content: "\e995";
}
.icon-arrow1:before {
  content: "\e996";
}
.icon-dollar-arrow:before {
  content: "\e997";
}
.icon-surface-terreno:before {
  content: "\e998";
}
.icon-sun-theme:before {
  content: "\e999";
}
.icon-key:before {
  content: "\e99a";
}
.icon-share1:before {
  content: "\e99b";
}
.icon-tick:before {
  content: "\e99c";
}
.icon-tour-360:before {
  content: "\e99d";
}
.icon-bed:before {
  content: "\e99e";
}
.icon-gym:before {
  content: "\e99f";
}
.icon-twitter1:before {
  content: "\e9a0";
}
.icon-instagram1:before {
  content: "\e9a1";
}
.icon-facebook1:before {
  content: "\e9a2";
}
.icon-home:before {
  content: "\e9a3";
}
.icon-location1:before {
  content: "\e9a4";
}
.icon-arrow-right:before {
  content: "\e9a5";
}
.icon-location-solid1:before {
  content: "\e9a6";
}
.icon-like:before {
  content: "\e9a7";
}
.icon-bathtub:before {
  content: "\e9a8";
}
.icon-car:before {
  content: "\e9a9";
}
.icon-surface:before {
  content: "\e9aa";
}
.icon-mosaico:before {
  content: "\e9ab";
}
.icon-lista:before {
  content: "\e9ac";
}
.icon-picture:before {
  content: "\e9ad";
}
.icon-investment1:before {
  content: "\e9ae";
}
.icon-leadership:before {
  content: "\e9af";
}
.icon-land1:before {
  content: "\e9b0";
}
.icon-solid-like:before {
  content: "\e9b1";
}
.icon-filter:before {
  content: "\e9b2";
}
.icon-3d:before {
  content: "\e9b3";
}
.icon-logo:before {
  content: "\e9b4";
}
.icon-bath:before {
  content: "\e9b5";
}
.icon-uniE927:before {
  content: "\e9b6";
}
.icon-uniE928:before {
  content: "\e9b7";
}
.icon-measure1:before {
  content: "\e9b8";
}
.icon-ico-sendmail:before {
  content: "\e9b9";
}
.icon-picture1:before {
  content: "\e9ba";
}
.icon-bed1:before {
  content: "\e9bb";
}
.icon-user:before {
  content: "\e9bc";
}
.icon-icon-dollar:before {
  content: "\e9bd";
}
.icon-land:before {
  content: "\e9be";
}
.icon-dollar:before {
  content: "\e9bf";
}
.icon-building:before {
  content: "\e9c0";
}
.icon-weird-square:before {
  content: "\e9c1";
}
.icon-icon-graph:before {
  content: "\e9c2";
}
.icon-icon-star:before {
  content: "\e9c3";
}
.icon-icon-location:before {
  content: "\e9c4";
}
.icon-delete:before {
  content: "\e9c5";
}
.icon-search1:before {
  content: "\e9c6";
}
.icon-tag:before {
  content: "\e9c7";
}
.icon-room:before {
  content: "\e9c8";
}
.icon-bed2:before {
  content: "\e9c9";
}
.icon-bathroom1:before {
  content: "\e9ca";
}
.icon-car1:before {
  content: "\e9cb";
}
.icon-noun_size_2965801-1:before {
  content: "\e9cc";
}
.icon-download:before {
  content: "\e9cd";
}
.icon-equalizer:before {
  content: "\e9ce";
}
.icon-whatsapp1:before {
  content: "\ea93";
}
.icon-whatsapp11:before {
  content: "\ea94";
}
.icon-arrow-light:before {
  content: "\e900";
}
.icon-arrow-solid:before {
  content: "\e901";
}
.icon-bathroom:before {
  content: "\e902";
}
.icon-bathroom-light:before {
  content: "\e903";
}
.icon-bathroom-solid:before {
  content: "\e904";
}
.icon-broker-access:before {
  content: "\e905";
}
.icon-broker-access-light:before {
  content: "\e906";
}
.icon-broker-access-solid:before {
  content: "\e907";
}
.icon-check:before {
  content: "\e908";
}
.icon-check-light:before {
  content: "\e909";
}
.icon-check-solid:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-close-light:before {
  content: "\e90c";
}
.icon-close-solid:before {
  content: "\e90d";
}
.icon-darkmode:before {
  content: "\e90e";
}
.icon-darkmode-light:before {
  content: "\e90f";
}
.icon-darkmode-solid:before {
  content: "\e910";
}
.icon-email:before {
  content: "\e911";
}
.icon-email-light:before {
  content: "\e912";
}
.icon-email-solid:before {
  content: "\e913";
}
.icon-expenses:before {
  content: "\e914";
}
.icon-expenses-light:before {
  content: "\e915";
}
.icon-expenses-solid:before {
  content: "\e916";
}
.icon-facebook:before {
  content: "\e917";
}
.icon-facebook-light:before {
  content: "\e918";
}
.icon-facebook-solid:before {
  content: "\e919";
}
.icon-faceing:before {
  content: "\e91a";
}
.icon-faceing-light:before {
  content: "\e91b";
}
.icon-faceing-solid:before {
  content: "\e91c";
}
.icon-favoritos-heart:before {
  content: "\e91d";
}
.icon-favoritos-heart-light:before {
  content: "\e91e";
}
.icon-favoritos-heart-solid:before {
  content: "\e91f";
}
.icon-favoritos-star:before {
  content: "\e920";
}
.icon-favoritos-star-light:before {
  content: "\e921";
}
.icon-favoritos-star-solid:before {
  content: "\e922";
}
.icon-filters:before {
  content: "\e923";
}
.icon-filters-light:before {
  content: "\e924";
}
.icon-filters-solid:before {
  content: "\e925";
}
.icon-floores-light:before {
  content: "\e926";
}
.icon-floors:before {
  content: "\e927";
}
.icon-floors-solid:before {
  content: "\e928";
}
.icon-gallery:before {
  content: "\e929";
}
.icon-gallery-light:before {
  content: "\e92a";
}
.icon-gallery-solid:before {
  content: "\e92b";
}
.icon-garden:before {
  content: "\e92c";
}
.icon-garden-light:before {
  content: "\e92d";
}
.icon-garden-solid:before {
  content: "\e92e";
}
.icon-grid-view:before {
  content: "\e92f";
}
.icon-grid-view-light:before {
  content: "\e930";
}
.icon-grid-view-solid:before {
  content: "\e931";
}
.icon-instagram:before {
  content: "\e932";
}
.icon-instagram-light:before {
  content: "\e933";
}
.icon-instagram-solid:before {
  content: "\e934";
}
.icon-investment:before {
  content: "\e935";
}
.icon-investment-light:before {
  content: "\e936";
}
.icon-investment-solid:before {
  content: "\e937";
}
.icon-investor-access:before {
  content: "\e938";
}
.icon-investor-access-light:before {
  content: "\e939";
}
.icon-investor-access-solid:before {
  content: "\e93a";
}
.icon-label:before {
  content: "\e93b";
}
.icon-label-light:before {
  content: "\e93c";
}
.icon-label-solid:before {
  content: "\e93d";
}
.icon-lightmode:before {
  content: "\e93e";
}
.icon-lightmode-light:before {
  content: "\e93f";
}
.icon-lightmode-solid:before {
  content: "\e940";
}
.icon-linkedin:before {
  content: "\e941";
}
.icon-linkedin-light:before {
  content: "\e942";
}
.icon-linkedin-solid:before {
  content: "\e943";
}
.icon-location:before {
  content: "\e944";
}
.icon-location-light:before {
  content: "\e945";
}
.icon-location-solid:before {
  content: "\e946";
}
.icon-measure:before {
  content: "\e947";
}
.icon-measure-light:before {
  content: "\e948";
}
.icon-measure-solid:before {
  content: "\e949";
}
.icon-oper-type:before {
  content: "\e94a";
}
.icon-oper-type-light:before {
  content: "\e94b";
}
.icon-oper-type-solid:before {
  content: "\e94c";
}
.icon-orientation:before {
  content: "\e94d";
}
.icon-orientation-light:before {
  content: "\e94e";
}
.icon-orientation-solid:before {
  content: "\e94f";
}
.icon-parking:before {
  content: "\e950";
}
.icon-parking-light:before {
  content: "\e951";
}
.icon-parking-solid:before {
  content: "\e952";
}
.icon-pet:before {
  content: "\e953";
}
.icon-pet-allow-light:before {
  content: "\e954";
}
.icon-pet-allow-solid:before {
  content: "\e955";
}
.icon-phone:before {
  content: "\e956";
}
.icon-phone-light:before {
  content: "\e957";
}
.icon-phone-solid:before {
  content: "\e958";
}
.icon-plus:before {
  content: "\e959";
}
.icon-plus-light:before {
  content: "\e95a";
}
.icon-plus-solid:before {
  content: "\e95b";
}
.icon-pool:before {
  content: "\e95c";
}
.icon-pool-light:before {
  content: "\e95d";
}
.icon-pool-solid:before {
  content: "\e95e";
}
.icon-prop-type:before {
  content: "\e95f";
}
.icon-prop-type-light:before {
  content: "\e960";
}
.icon-prop-type-solid:before {
  content: "\e961";
}
.icon-quote:before {
  content: "\e962";
}
.icon-quote-light:before {
  content: "\e963";
}
.icon-quote-solid:before {
  content: "\e964";
}
.icon-rooms:before {
  content: "\e965";
}
.icon-rooms-light:before {
  content: "\e966";
}
.icon-rooms-solid:before {
  content: "\e967";
}
.icon-row-view:before {
  content: "\e968";
}
.icon-row-view-light:before {
  content: "\e969";
}
.icon-row-view-solid:before {
  content: "\e96a";
}
.icon-search:before {
  content: "\e96b";
}
.icon-search-light:before {
  content: "\e96c";
}
.icon-search-solid:before {
  content: "\e96d";
}
.icon-share:before {
  content: "\e96e";
}
.icon-share-light:before {
  content: "\e96f";
}
.icon-share-solid:before {
  content: "\e970";
}
.icon-toilette:before {
  content: "\e971";
}
.icon-toilette-light:before {
  content: "\e972";
}
.icon-toilette-solid:before {
  content: "\e973";
}
.icon-tour:before {
  content: "\e974";
}
.icon-tour-light:before {
  content: "\e975";
}
.icon-tour-solid:before {
  content: "\e976";
}
.icon-twitter:before {
  content: "\e977";
}
.icon-twitter-light:before {
  content: "\e978";
}
.icon-twitter-solid:before {
  content: "\e979";
}
.icon-whatsapp:before {
  content: "\e97a";
}
.icon-whatsapp-light:before {
  content: "\e97b";
}
.icon-whatsapp-solid:before {
  content: "\e97c";
}
.icon-youtube:before {
  content: "\e97d";
}
.icon-youtube-light:before {
  content: "\e97e";
}
.icon-youtube-solid:before {
  content: "\e97f";
}
.icon-age:before {
  content: "\e980";
}
.icon-age-light:before {
  content: "\e981";
}
.icon-age-solid:before {
  content: "\e982";
}
.icon-aircond:before {
  content: "\e983";
}
.icon-aircond-light:before {
  content: "\e984";
}
.icon-aircond-solid:before {
  content: "\e985";
}
.icon-amenities:before {
  content: "\e986";
}
.icon-amenities-light:before {
  content: "\e987";
}
.icon-amenities-solid:before {
  content: "\e988";
}
.icon-arrow:before {
  content: "\e989";
}
