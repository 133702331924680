.novedad {
  .layout {
    .content {
      .div_section_form {
        background-color: white;
        .container-fluid {
          .div_news_container {
            border-color: black;
            .div_vertical {
              p {
                color: black;
                background-color: orange;
              }
            }
            .row {
              .div_title {
                border-color: black;
              }
              .div_first {
                h3 {
                }
                p {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
}
