.Emprendimiento {
    .main {
        height: 100vh;
        margin-top: -114px;
        margin-bottom: 10vh;
        .art {
            .text-content {
                z-index: 2;
                text-align: right;
                height: 100vh;
                // padding-top: 10vh;
                h2 {
                    font-size: 3.5rem;
                    font-family: 'montserrat bold', sans-serif;
                    letter-spacing: 1px;
                }
                h4 {
                    margin: 1.4rem 0 1.4rem 0;
                    font-size: 1.8rem;
                    max-width: 55vw;
                    letter-spacing: 1px;
                }
                p {
                    font-weight: 400;
                    font-size: 1.3rem;
                    letter-spacing: 1.5px;
                    line-height: 1.4;
                    max-width: 45vw;
                    margin-bottom: 4rem;
                    font-family: 'montserrat regular', sans-serif
                }
            }
        }
        .owl-carousel {
            height: calc(100vh);
            // margin-top: -114px;
            // overflow: hidden;
            position: absolute;
            .owl-item {
                margin: 0;
                position: relative;
                img,
                .opacity {
                    object-fit: cover;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: calc(100vh);
                }
                .opacity {
                    z-index: 1;
                    opacity: .7;
                    background-color: #202020A7;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .main {
            .art {
                .text-content {
                    text-align: center;
                    // padding-top: 10vh;
                    h2 {
                        font-size: 2.5rem;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }
                    h4 {
                        margin: 1rem 0 1rem 0;
                        font-size: 1.4rem;
                        max-width: 90vw;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    p {
                        font-size: 1rem;
                        max-width: 90vw;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}