#top-alert.alert {
    // top: -57px;
    top: -87px;
    // top: calc(0vh - 12rem);
    width: 100%;
    color: #fff;
    font-weight: 100;
    margin-bottom: 0;
    height: 5rem;
    position: fixed;
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 10000000;
    background-color: var(--secondary-color);
    transition: .5s all ease-in-out;
    @media (max-width: 992px) {
        top: -16rem;
    }
    &.counter-wrapper-size {
        @media (min-width: 993px) {
            height: 5rem;
        }
        @media (min-width: 962px) and (max-width: 992px) {
            height: 6rem;
        }
        @media (min-width: 828px) and (max-width: 961px) {
            height: 7rem;
        }
        @media (max-width: 827px) {
            height: 11rem;
        }
        @media (min-width: 510px) and (max-width: 826px) {
            height: 12rem;
        }
        @media (max-width: 509px) {
            height: 12.5rem;
        }
    }
    @media (min-width: 993px) {
        height: 5rem;
    }
    @media (min-width: 962px) and (max-width: 992px) {
        height: 6rem;
    }
    @media (max-width: 961px) {
        height: 7.5rem;
    }
    &.animate-show {
        top: 0;
    }
    .container-fluid {
        @media (max-width: 827px) {
            max-width: 56vw;
            flex-direction: column;
        }
        .counter-wrapper {
            min-height: 16px;
            align-items: center;
            flex-direction: row;
            display: inline-flex;
            @media (max-width: 826px) {
                flex-direction: column;
            }
            .counter {
                color: var(--primary-color);
                margin-bottom: 0.2rem;
                @media (max-width: 826px) {
                    margin-top: 0.25rem;
                    margin-bottom: -0.2rem;
                }
                span {
                    font-weight: 100;
                    span.date-val {
                        font-weight: 400;
                        font-size: 1.5rem;
                        @media (max-width: 991px) {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .text-content {
            display: inline-block;
            @media (max-width: 827px) {
                width: 100%;
            }
        }
        .btn-link {
            color: #fff;
            border-radius: 4px;
            margin-left: 0.8rem;
            display: inline-block;
            text-decoration: none;
            padding: 0.4rem 0.8rem;
            max-width: fit-content;
            background-color: var(--primary-color);
            &:hover {
                cursor: pointer;
                background-color: #b18442ad;
            }
            @media (max-width: 961px) {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .btnClose {
        right: 0;
        top: 10%;
        z-index: 2;
        color: #fff;
        cursor: pointer;
        font-size: 1.2rem;
        position: absolute;
        font-family: inherit;
        text-decoration: none;
        max-width: 59px!important;
        padding: 0.25rem 1.5rem!important;
        content: '';
        &::before {
            width: 0px;
            height: 0px;
            content: '';
            display: none;
        }
        &:focus,
        &:focus-visible {
            outline: 0 none;
            box-shadow: none;
            border-color: transparent;
        }
    }
}

#top-alert.alert.animate-show + div + .fixed-bar + header.header[role=header],
#top-alert.alert.animate-show + div + .novedades > header.header[role=header],
#top-alert.alert.animate-show + div + .emprendimientos > .layout > header.header[role=header] {
    top: 4rem!important;
}

#top-alert.alert.animate-show + div + .fixed-bar + header + main > div > aside.sidebar > a.brand,
#top-alert.alert.animate-show + div + .novedades > header + div > .layout.main__container > aside.sidebar > a.brand,
#top-alert.alert.animate-show + div + .emprendimientos > .layout > aside.sidebar > a.brand {
    top: -214px;
}
