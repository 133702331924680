// Hidden button
button#squarePopUpBtn {
    top: 0;
    margin: 0;
    border: 0;
    padding: 0;
    width: 1px;
    z-index: -1;
    height: 1px;
    position: absolute;
}

// Modal styles
div#squarePopUp.modal {
    .modal-dialog {
        display: flex;
        height: 100vh;
        align-items: center;
        &.square {
            max-width: 50vh;
            &.align-center,
            &.align-right,
            &.align-left {
                .modal-content {
                    width: 22vw;
                    height: 87vh;
                    @media(max-width: 992px) {
                        height: 75% !important;
                        width: 100% !important;
                    }
                }
            }
        }
        &.flat-width {
            max-width: unset;
        }
        .modal-content {
            height: 80vh;
            border: none;
            background-clip: unset;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @media(max-width: 992px) {
                height: calc(100vw * 0.75);
            }
            // background-image: url('../images/Baigun-oficinas.jpg');
            &.square {
                height: 55%;
                @media (max-width: 992px) {
                    height: 45%;
                }
            }
            .modal-header {
                border: none;
                display: flex;
                margin-left: auto;
                justify-content: flex-end;
                .btnClose {
                    color: #fff;
                    font-size: 2rem;
                    text-decoration: none;
                    text-shadow: 0 0 3px rgba(0, 0, 0, .6);
                    &:hover {
                        cursor: pointer;
                    }
                    max-width: 59px!important;
                    padding: 0.25rem 1.5rem!important;
                    content: '';
                    &::before {
                        width: 0px;
                        height: 0px;
                        content: '';
                        display: none;
                    }
                    &:focus,
                    &:focus-visible {
                        outline: 0 none;
                        box-shadow: none;
                        border-color: transparent;
                    }
                }
            }
            // Flat - limit height
            &.flat-width {
                height: 35vh;
                width: calc(35vh * 4);
                margin: auto;
            }
            // Modal body with link
            .modal-body {
                z-index: 10000000!important;
                width: 100%;
                height: 75vh;
                bottom: 0;
                position: absolute;
                &.flat-width {
                    // height: 39vh;
                    height: 82%;
                }
                &.square {
                    height: 55%;
                }
            }
            &:hover {
                cursor: pointer;
            }
        }
        // Responsive modal
        @media (min-width: 576px) {
            max-width: 49vw;
        }
        // Alignment
        &.align-left,
        &.align-right,
        &.align-center {
            max-width: 89vw;
            .modal-body {
                width: 100%;
            }
            .modal-content {
                width: 25vw;
                // width: 45%;
                @media (min-width: 992px) and (max-width: 1200px) {
                    width: 38vw;
                }
            }
        }
        &.align-left {
            margin-top: auto;
            justify-content: flex-start;
            @media (max-width: 991px) {
                margin: 0 auto;
                justify-content: center;
                .modal-content {
                    width: 85vh;
                    // width: 75%;
                }
            }
        }
        &.align-right {
            margin-top: auto;
            justify-content: flex-end;
            @media (max-width: 991px) {
                margin: 0 auto;
                justify-content: center;
                .modal-content {
                    width: 85vh;
                    // width: 75%;
                }
            }
        }
        &.align-center {
            margin: 0 auto;
            justify-content: center;
            @media (max-width: 991px) {
                .modal-content {
                    width: 85vh;
                    // width: 75%;
                }
            }
        }
    }
}

.modal-open .modal {
    z-index: 10000;
    overflow-x: hidden;
    overflow-y: hidden;
}

.article.square-popup {
    position: absolute;
    z-index: 10000001!important;
}